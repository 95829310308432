/* Variables */

/* @import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&display=swap'); */

:root {
  --primary-color: #1a73e8;
  --secondary-color: #f1f3f4;
  --text-color: #202124;
  --font-stack: 'Maitree' ;
}
/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* font-family: 'Maitree', sans-serif; */
  color: var(--text-color);
  line-height: 1.6;
}
/* Header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
nav ul {
  display: flex;
  list-style: none;
}
nav ul li {
  margin-left: 1.5rem;
}
nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  transition: color 0.3s ease;
}
nav ul li a:hover {
  color: var(--primary-color);
}
/* Hero Section */
.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('hero-bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
}
.hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}
.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.cta-button:hover {
  background-color: #1669c9;
}
/* Services Sections */
.service-section {
  padding: 4rem 0;
}
.service-section:nth-child(even) {
  background-color: var(--secondary-color);
}
.service-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}
.service-description {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
/* About Section */
.about-section {
  padding: 4rem 0;
  text-align: center;
}
.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.team-member {
  display: inline-block;
  width: 200px;
  margin: 1rem;
  text-align: center;
}
.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}
/* Contact Section */
.contact-section {
  padding: 4rem 0;
  text-align: center;
}
.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
form {
  max-width: 600px;
  margin: 0 auto;
}
input,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #1669c9;
}
/* Footer */
footer {
  background-color: #f1f1f1;
  padding: 2rem 0;
  text-align: center;
}
footer p {
  margin-bottom: 1rem;
}
footer a {
  color: var(--text-color);
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}/* Variables */
:root {
  --primary-color: #1a73e8;
  --secondary-color: #f1f3f4;
  --text-color: #202124;
  --font-stack: 'Roboto', sans-serif;
}
/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--font-stack);
  color: var(--text-color);
  line-height: 1.6;
}
/* Header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

img {
  width: 8%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
nav ul {
  display: flex;
  list-style: none;
}
nav ul li {
  margin-left: 1.5rem;
}
nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  transition: color 0.3s ease;
}
nav ul li a:hover {
  color: var(--primary-color);
}
/* Hero Section */
.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('hero-bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
}
.hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}
.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.cta-button:hover {
  background-color: #1669c9;
}
/* Services Sections */
.service-section {
  padding: 4rem 0;
}

.service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.service-image {
  flex: 0 0 40%;
  padding: 0 2rem;
}

.service-image img {
  width: 100%;
  height: auto;
  border-radius: 30%;
}

.service-description {
  flex: 0 0 60%;
  padding: 0 2rem;
}

.service-description p {
  font-size: 1.2em;
}

.service-image.left {
  order: -1;
}

.service-image.right {
  order: 1;
}

.service-section:nth-child(even) {
  background-color: var(--secondary-color);
}
.service-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}
/* .service-description {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
} */
/* About Section */
.about-section {
  padding: 4rem 0;
  text-align: center;
}
.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.team-member {
  display: inline-block;
  width: 200px;
  margin: 1rem;
  text-align: center;
}
.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}
/* Contact Section */
.contact-section {
  padding: 4rem 0;
  text-align: center;
}
.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
form {
  max-width: 600px;
  margin: 0 auto;
}
input,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #1669c9;
}
/* Footer */
footer {
  background-color: #f1f1f1;
  padding: 2rem 0;
  text-align: center;
}
footer p {
  margin-bottom: 1rem;
}
footer a {
  color: var(--text-color);
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}